.App {
  text-align: center;
  font-family: "Noto Sans JP";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html {
  overflow:auto;
  height: 100%;
}
body {
  overflow: auto;
  height: 100%;
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

h1 {
  /* margin: 10px 0px; */
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

dt {
  font-weight: bold;
  display: inline-block;
}
dd {
  display: inline-block;
  margin-left: 10px;
}

img {
  max-width: 100%;
  max-height: 100%;
}